<script lang="ts" setup>
interface Props {
    message?: string;
}

const props = defineProps<Props>()
</script>

<template>
    <TextCaption v-if="props.message" class="text-red-600">
        {{ props.message }}
    </TextCaption>
</template>

<style scoped></style>
