import { z } from 'zod'
import type { ComponentBindsConfig } from 'vee-validate'
import type {
  FormBinds,
  FormContext,
  FormOptions,
  FormSchema,
} from '../types/forms'

const fieldBindOptions: Partial<ComponentBindsConfig> = {
  validateOnBlur: false,
  validateOnModelUpdate: true,
}

function isEmpty(value: any) {
  if (Array.isArray(value)) {
    return value.length === 0
  }

  return value === undefined || value === null || value === ''
}

export const useFormContextModel = <T extends FormSchema>(
  options: FormOptions<T>
): FormContext<T> => {
  const {
    values,
    errors,
    meta,
    isSubmitting,
    defineComponentBinds,
    setValues,
    setErrors,
    resetForm,
    handleSubmit,
  } = createVeeForm()

  const fields = defineFormBinds()

  const autoSaveTask = createAutoSaveDebouncedTask()

  const isSaved = ref(false)
  const isValid = computed(() => meta.value.valid)
  const isDirty = computed(() => meta.value.dirty)

  /**
     * Creates a vee-validate form instance with the given options
     * @returns an instance of vee-validate form
     */
  function createVeeForm() {
    const validation = options.rules
      ? toTypedSchema(z.object(options.rules))
      : undefined

    return useForm<T>({
      initialValues: unref(options.initial),
      validationSchema: validation,
      validateOnMount: false,
    })
  }

  /**
     * Creates a reactive form model with vee-validate component bindings to be used with v-bind
     * @returns Reactive form fields model
     */
  function defineFormBinds() {
    const model = reactive<FormBinds<T>>({} as FormBinds<T>)

    for (const fieldName of Object.keys(values)) {
      // @ts-expect-error - toRef is not typed correctly
      const field = toRef(model, fieldName)

      field.value = defineComponentBinds(
                fieldName as any,
                fieldBindOptions
      )
    }

    return model
  }

  function createAutoSaveDebouncedTask() {
    if (options.autoSave?.enabled !== true) {
      return undefined
    }

    const delay = options.autoSave?.delayInMs ?? 1000

    return useDebounceFn(autoSaveForm, delay)
  }

  /**
     * Normalizes form values and pass it to the onSubmit callback
     * @returns void
     */
  function submitForm() {
    if (options.onSubmit === undefined) {
      return
    }

    handleSubmit((values) => {
      const payload = preparePayloadValues(values)

      options.onSubmit?.(payload)
    })()
  }

  /**
     * Normalizes form values and pass it to the onAutoSave callback
     * @returns void
     */
  function autoSaveForm() {
    if (isSaved.value === true) {
      return
    }

    if (options.autoSave?.onAutoSave === undefined) {
      return
    }

    const payload = preparePayloadValues(values)

    options.autoSave.onAutoSave(payload)
  }

  /**
     * Normalizes form values according to zod validation schema.
     *
     * During normalization, the following rules are applied:
     * - Converts z.nullable() to null if the value is empty
     * - Converts z.optional() to undefined if the value is empty
     *
     * Note: z.nullable() should be checked before z.optional() because they can be used together
     */
  function preparePayloadValues(model: T) {
    const payload = {} as T

    // rewrite to for (const in) loop
    for (const [key, value] of Object.entries(model)) {
      const rule = options.rules?.[key]

      if (rule === undefined) {
        Object.assign(payload, { [key]: value })
      } else if (rule.isNullable() && isEmpty(value)) {
        Object.assign(payload, { [key]: null })
      } else if (rule.isOptional() && isEmpty(value)) {
        Object.assign(payload, { [key]: undefined })
        continue
      }

      Object.assign(payload, { [key]: value })
    }

    return payload
  }

  /**
     * Executes the auto save task when the form values change
     * @returns void
     */
  function onFormValuesChange() {
    if (autoSaveTask === undefined) {
      return
    }

    autoSaveTask()
  }

  /**
     * Watches for changes in the form values and triggers the auto save task
     */
  watch(
    () => values,
    () => onFormValuesChange(),
    { deep: true }
  )

  return {
    values,
    fields,
    errors: toReactive(errors),
    submit: submitForm,
    reset: resetForm,
    isSubmitting,
    isDirty,
    isValid,
    setValues,
    setErrors,
  }
}
